.contact-us-main {
  .banner {
    //height: 300px;
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    //background-color: rgb(149, 66, 66);
    background-image: url("../../Assets/Image/coursebg.png");
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    color: white;
    display: flex;
    justify-content: center;

    &::before {
      position: absolute;
      content: "";
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      z-index: 2;
      background-color: #002147be;
    }

    h2 {
      z-index: 3;
    }
  }

  .contact-us-inner {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    .info-section {
      display: grid;
      gap: 2rem;
      grid-template-columns: repeat(3, 1fr);
      justify-content: space-between;
      padding-top: 100px;
      padding-bottom: 100px;

      .info {
        background: #f0f4f9;
        padding: 25px;
        border-radius: 6px;
        margin-bottom: 30px;
        display: flex;

        span {
          &:nth-child(1) {
            height: 70px;
            width: 70px;
            line-height: 70px;
            border-radius: 50%;
            background: orange;
            text-align: center;
            margin-right: 16px;
          }
          &:nth-child(2) {
            h2 {
              font-weight: 700;
              font-size: 20px;
              color: #002147;
            }

            h5 {
              color: gray;
              font-weight: 500;
            }
          }
        }

        @media (max-width:500px) {
          padding: 10px;
        }
      }

      @media (max-width:850px) {
       
        grid-template-columns: repeat(2, 1fr);
        padding-left: 20px;
        padding-right: 20px;
      }
      @media (max-width:700px) {
        grid-template-columns: repeat(1, 1fr);
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    //////////////////////////////////////////////
    ///
    ///
    ///
    .form-section-contact {
      display: flex;
      width: 100%;

      .form-section-text {
        width: 30%;
        //background-color: red;

        h4 {
          font-weight: 500;
          color: #002147;
          font-size: 16px;
        }
        h2 {
          font-weight: 700;
          color: #002147;
          font-size: 46px;
          margin-top: 5px;
        }

        p {
          color: gray;
          margin-top: 1rem;
        }

        @media (max-width:850px) {
          width: 100%;
          margin-bottom: 30px;
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      .form-main {
        width: 70%;
        //background-color: gray;

        form {
          padding-left: 15px;
          padding-right: 15px;
          display: flex;
          flex-direction: column;
          .form-input-1,
          .form-input-2 {
            display: flex;

            div {
              width: 50%;
              margin-bottom: 20px;
              padding-left: 15px;
              padding-right: 15px;
              input {
                width: 100%;
                border: 2px solid rgba(8, 76, 148, 0.2) !important;
                height: 58px;
                border-radius: 4px;
                padding: 0 18px;
                outline: none;
              }
            }
          }

          textarea {
            //width: 100%;
            border: 2px solid rgba(8, 76, 148, 0.2) !important;
            height: 140px;
            border-radius: 5px;
            padding: 14px 18px;
            margin-left: 15px;
            margin-right: 15px;
          }

          .contact-sub-btn {
            padding-left: 15px;
            padding-top: 20px;
            button {
              height: 55px;
              line-height: 55px;
              padding: 0 36px;
              border-radius: 0;
              border: 0;
              font-size: 15px;
              transition: all 0.5s ease;
              font-weight: 500;
              border-radius: 4px;
              background-color: orange;
              cursor: pointer;
            }
          }
        }

        @media (max-width:850px) {
          width: 100%;
          margin-bottom: 100px;
        }
      }

      @media (max-width:850px) {
        flex-direction: column;
      }
    }
  }
}
