.signup-main {
  display: flex;
  min-height: 100svh;
  align-items: center;

  .signup-success {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100svh;
    background-color: rgb(223, 223, 223);
    .signup-success-inner {
      padding: 20px;
      border-radius: 8px;
      height: 400px;
      width: 400px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: white;

      @media (max-width: 500px) {
        height: 300px;
        width: 300px;
      }

      span {
        font-size: 7rem;
        color: green;

        @media (max-width: 500px) {
          font-size: 3.5rem;
        }
      }

      h1 {
        font-size: 25px;
        font-weight: 700;
      }
    }
  }

  .signup-inner {
    padding: 20px;
    display: flex;
    align-items: center;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;

    .signup-sec1 {
      width: 50%;
      height: auto;
      margin: 0 auto;
      //background-color: rgba(215, 214, 214, 0.332);
      padding-left: 100px;
      padding-right: 100px;
      padding-top: 100px;
      // padding-bottom: 100px;

      .signup-logo {
        position: absolute;
        top: 20px;
        left: 50px;
        font-weight: 700;
        font-size: 40px;

        @media (max-width: 700px) {
          text-align: center;
          top: 20px;
          left: 10px;
          font-size: 25px;
        }
      }

      .signup-form-main {
        //background-color: white;
        width: 100%;
        height: fit-content;

        form {
          display: flex;
          flex-direction: column;

          .form-heading {
            h1 {
              margin-bottom: 20px;
              font-size: 1.5rem;
              font-weight: 700;
            }

            h5 {
              margin-bottom: 20px;
              font-size: 1rem;
              font-weight: 400;
            }

            @media (max-width: 700px) {
              h1 {
                font-size: 1rem;
                font-weight: 500;
              }

              h5 {
                font-size: 0.7rem;
                font-weight: 400;
              }
            }
          }
          .input-box {
            position: relative;
            display: flex;
            width: 100%;
            align-items: center;

            input[type="text"],
            [type="email"] {
              width: 100%;
              margin-bottom: 15px;
              height: 60px;
              padding: 20px;
              background-color: white !important;
              border: 1px solid rgb(213, 213, 213);
              border-radius: 50px;
              outline: none;
            }

            .input-check {
              position: absolute;
              right: 20px;
              top: 30px;
              cursor: pointer;
              border: none;
              background-color: transparent;
            }
          }
          .password-box {
            position: relative;
            display: flex;
            width: 100%;
            align-items: center;

            input[type="password"],
            [type="text"] {
              width: 100%;
              margin-bottom: 15px;
              height: 60px;
              padding: 20px;
              background-color: white !important;
              border: 1px solid rgb(213, 213, 213);
              border-radius: 50px;
              outline: none;
            }

            button {
              position: absolute;
              right: 50px;
              top: 5;
              cursor: pointer;
              border: none;
              background-color: transparent;
            }

            .input-check {
              position: absolute;
              right: 20px;
              cursor: pointer;
              border: none;
              background-color: transparent;
            }
          }

          .signup-agree {
            display: flex;
            align-items: center;
            justify-content: left;
            margin-bottom: 20px;

            h4 {
              font-size: 14px;
              font-weight: 300;
            }
          }

          .register-btn-main {
            button {
              border: none;
              background-color: black;
              color: white;
              font-size: 20px;
              width: 100%;
              padding: 20px;
              border-radius: 10px;
              cursor: pointer;
              transition: 0.5s;

              &:hover {
                background-color: rgb(42, 41, 41);
              }

              @media (max-width: 500px) {
                font-size: 17px;
                padding: 10px;
              }
            }
          }

          .signup-continue {
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            span {
              width: 100px;
              background-color: gray;
              height: 2px;
            }

            h6 {
              font-size: 15px;
              margin-left: 10px;
              margin-right: 10px;
              text-align: center;

              a {
                text-decoration: underline;
                cursor: pointer;
                color: black;
              }
            }
          }
        }
      }

      @media (max-width: 1300px) {
        padding-left: 50px;
        padding-right: 50px;
      }
      @media (max-width: 1200px) {
        width: 60%;
        padding-left: 100px;
        padding-right: 100px;
        margin: 0 auto;
      }
      @media (max-width: 900px) {
        width: 100%;
      }
      @media (max-width: 768px) {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    @media (max-width: 700px) {
      padding: 10px;
    }
  }
}
