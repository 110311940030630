.airtime-main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: whitesmoke;
  padding: 20px;
  .airtime-inner {
    background-color: white;
    height: fit-content;
    width: 500px;
    padding: 25px;
    border-radius: 7px;
    z-index: 0;
    position: relative;
    box-shadow: 1px 2px 7px 2px rgba(141, 141, 141, 0.529);

    form {
      display: flex;
      flex-direction: column;
      margin-top: 40px;

      label {
        margin-bottom: 5px;
      }

      select,
      input {
        height: 35px;
        padding-left: 20px;
        padding-right: 20px;
        outline: none;
        margin-bottom: 15px;
        border-radius: 5px;
        border: 1px solid gray;
        font-size: 18px;
        font-weight: 500;

        @media (max-width: 500px) {
          font-size: 14px;
        }
      }

      .recharge-btn {
        height: 50px;
        background-color: black;
        color: white;
        border: none;
        margin-top: 10px;
        border-radius: 7px;
        font-size: 18px;
        font-weight: 500;
        cursor: pointer;
        transition: 0.5s;

        @media (max-width: 500px) {
          font-size: 14px;
        }

        &:hover {
          background-color: rgb(103, 77, 77);
        }
      }
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      height: 15px;
      width: 100%;
      background-color: black;
      border-bottom-left-radius: 100px;
      border-bottom-right-radius: 100px;
    }
  }
}
