.header-nav {
    width: 100%;
    height: 100px;
    //background-color: bisque;
    position: fixed;
   // padding-left:283px ;
    box-shadow: 3px 0px 10px rgba(151, 151, 151, 0.503);
    background-color: white;
    //z-index: 2;
    //display: none;
    z-index: 2;

    .header-nav-inner {
        width: 100%;
        height: 100%;
       padding: 0px 60px 0px 60px;
        display: flex;
        align-items: center;
        justify-content: center;

        .drawer-container {

            @media (min-width:970px) {
                display: none;
            }
        }
        
        .header-nav-inner-main {
           display: flex;
           position: relative;
           width: 100%;
           height: 50px;

           /*
            .search {
                width: 400px;
                height: 40px;
                display: inline-flex;
                position: relative;
              
              
                input[type="text"] {
                    height: 100%;
                    width: 100%;
                    border: 1px solid #213F7D;
                    border-radius: 8px;
                    padding-left: 15px;
                    outline: none;
                    font-family: 'Work Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                   
                }

                span {
                    height: 100%;
                    width: 56px;
                    background: #39CDCC;
                    position: absolute;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    color: white;
                    right: 0;
                    border-bottom-right-radius: 8px;
                    border-top-right-radius: 8px;
                    
                }
            }
            */
            

            .Profile-section {
                display: flex;
                position: absolute;
                width: 250px;
                height: fit-content;
                //background-color: #213F7D;
                right: 0px;
                align-items: center;
                justify-content: space-between;
                //background-color: #1c5ad6;
                .text {
                    text-decoration: underline;
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    color: #213F7D;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .noti-icon {
                    font-size: 20px;
                    color: #213F7D;

                    &:hover {
                        cursor: pointer;
                    }

                }

                .profile-picture {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    //background-color: red;
                    width: fit-content;

                    img {
                        height: 40px;
                        width: 40px;
                        border-radius: 50%;
                        cursor: pointer;
                    }
                    .admin-name {
                        margin-left: 10px;
                        font-size: 18px;
                        color: black;
                        font-weight: 500;

                        @media (max-width:500px) {
                            font-size: 15px;
                        }
                    }
                    .drop-icon {
                        margin-left: 10px;
                        font-size: 20px;
                        color: #213F7D;

                        &:hover {
                            cursor: pointer;
                        }
                    }

                    .pop {
                        .popover {
                            cursor: pointer;
                            @media (max-width:500px) {
                                height: 34px;
                                width: 34px;
                               
                            }

                            

                           
                        }
                    }
                }
            }

            @media (max-width:500px) {
              height: 25px;
            }
        }

        @media (max-width:500px) {
            padding: 0px 10px 0px 15px;
        }
    }


    @media (max-width:500px) {
        height: 70px;
    }

}