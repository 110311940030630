.testiomonal-main {
    display: flex;
    justify-content: center;
    background-image: url("../../Assets/Image/testimonialbg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
   // margin-top: 100px;
    //background-color: red;
    padding-bottom: 150px;

    .testiomonal-inner {
      max-width: 1200px;
      width: 100%;

      .testimonial-heading-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 150px;

        .testimonial-heading-inner {
          display: flex;
          align-items: center;

          span {
            height: 1px;
            background-color: navy;
            width: 100px;
          }

          h3 {
            margin-left: 10px;
            margin-right: 10px;
            font-size: 16px;
            font-weight: 500;
          }
        }

        .testimonial-heading1 {
          font-size: 46px;
          font-weight: 700;
          margin: 0;

          @media (max-width:700px) {
            font-size: 30px;
            text-align: center;
          }
        }
      }

      .test-card-section {
        // display: grid;
        // gap: 2rem;
        // grid-template-columns: repeat(2, 1fr);
        //margin-top: 100px;
        padding-top: 30px;
        padding-bottom: 30px;

        .swiper {
          padding-bottom: 40px;

          .test-card-main {
            background-color: white;
            border-radius: 10px;
            padding: 20px;
            max-width: 600px;
            height: auto;
           width: 100%;

            .test-card-quotes {
              
              font-size: 20px;
              padding: 10px;
              color: rgb(85, 83, 248);

            }

            .feed-slide-title {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .feed-slide-title1 {
                width: 400px;
                color: #666;
                font-size: 14px;
                font-weight: 600;
                line-height: 1.764;
              }

              .feed-dash-img {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                border: 2px dashed rgb(84, 84, 255);
                border-radius: 50%;

                .feed-slide-img {
                  height: 70px;
                  border-radius: 50%;
                }
              }

              @media (max-width:700px) {
                flex-direction: column;
                text-align: center;

                .feed-slide-title1{
                  width: 100%;
                }
              }
            }

             .feed-slide-subtitle{
              color: black;
              font-weight: 700;
             }
            
          }
        }

        @media (max-width:700px) {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }

    @media (max-width:700px) {
      background-size: cover;
    }
  }