.all-course-main {

    .banner {
        //height: 300px;
        padding-top: 100px;
        padding-bottom: 100px;
        position: relative;
        //background-color: rgb(149, 66, 66);
        background-image: url("../../Assets/Image/coursebg.png");
        background-repeat: no-repeat;
        background-size: cover;
        z-index: 0;
        color: white;
        display: flex;
        justify-content: center;

        &::before {
            position: absolute;
            content: "";
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            z-index: 2;
            background-color:#002147be;

        }

        h2{
            z-index: 3;
        }

    }

  .all-course-main-inner{
   display: flex;
   flex-direction: column;
   align-items: center;

    .featured-courses-card {
        max-width: 1200px;
        width: 100%;
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 50px;

        .courses-card-main {
          border: 1px solid rgba(188, 187, 187, 0.548);
          overflow: hidden;
          width: 100%;
          border-radius: 10px;

          img {
            width: 100%;
            transition: 0.5s;
            background-color: black;
          }

          .courses-card {
            //max-width: 100%;
            //background-color: brown;
            transition: 0.5s;
            .courses-card-desc {
              padding: 14px 20px;
              margin-top: 0;

              .courses-card-desc-inner {
                display: flex;
                justify-content: space-between;
                align-items: center;
                //background-color: green;
                .courses-card-desc-title {
                  font-size: 20px;
                }
                .courses-card-desc-icon {
                  font-size: 25px;
                }
              }
            }
          }

          &:hover img {
            transform: scale(1.1);
          }
          &:hover .courses-card {
            background-color: #002147;
            color: white;
          }
        }
      }

  }
}