* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
html {
    scroll-behavior: smooth;
}
.instructions {
    font-size: 0.75rem;
    border-radius: 0.5rem;
    background: #000;
    color: #fff;
    padding: 0.25rem;
    position: relative;
   z-index: 3;
}

.instructions > svg {
    margin-right: 0.25rem;
}

.offscreen {
    position: absolute;
    left: -9999px;
}

.hide {
    display: none;
}

.valid {
    color: limegreen;
    margin-left: 0.25rem;
}

.invalid {
    color: red;
    margin-left: 0.25rem;
}

.errmsg {
    background-color: lightpink;
    color: firebrick;
    font-weight: bold;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
}
.line {
    display: inline-block;
}


.chakra-modal__content-container {
    align-items: center!important;
}

.modal-text {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    h1 {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: 700;
    }
}

.dash-menu {
    position: absolute;
    z-index: 3;
}

.chakra-avatar__initials {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pop-body-main {

    .pop-body {
        display: flex!important;
        flex-direction: column!important;

       hr {
        margin-top: 10px;
        margin-bottom: 10px;
       }
    }

}