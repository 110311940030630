.sidebar-parent {
    height: 100vh;
    width: 283px;
    //background-color: aqua;
    //display: none;
    padding-left: 30px;
    overflow-y: scroll;
    position: fixed;
    background-color: navy;
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
    z-index: 4;

    &::-webkit-scrollbar {
        display: none;
    }

    .sidebar-inner {
        width: 100%!important;
      
        //overflow-y: scroll;

    .sidebar-logo {
        height: 100px;
        width: 100%;
        //background-color: white;
        display: flex;
        color: white;
        //justify-content: center;
        align-items: center;

        .sidebar-logo-inner {
            width: 144.8px;
            height: fit-content;
           // background-color: brown;
            display: inline-flex;
            justify-content: space-between;
            font-size: 2rem;
            font-weight: 700;
            img:nth-child(1) {
                height: 20.8px;
                width: 20.6px;
            }
            img:nth-child(2) {
                height: 30px;
               
            }
        }
        
    }

     

}

.logout-btn{
    margin-top: 5rem;
    position: absolute;
    bottom: 20px;
    width: 100%;
    padding-right: 40px;

    button {

        width: 100%;
        height: 40px;
        border: none;
        background-color: rgb(57, 57, 132);
        font-size: 20px;
        font-weight: 500;
        color: white;
        text-align: left;
        text-align: center;
        cursor: pointer;
        transition: .5s;

        &:hover{
            background-color: whitesmoke;
            color: black;
        }
       
    }
}

@media (max-width:970px) {
    display: none;
}

    
}