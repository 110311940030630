.login-main {
  display: flex;
  align-items: center;
  min-height: 100svh;
  position: relative;

  .login-inner {
    padding: 20px;
    display: flex;
    align-items: center;
    max-width: 1500px;
    width: 100%;
    margin: 0 auto;

    .login-sec1 {
      width: 50%;
      height: auto;
      margin: 0 auto;
      //background-color: rgba(215, 214, 214, 0.332);
      padding-left: 100px;
      padding-right: 100px;
      padding-top: 100px;
      // padding-bottom: 100px;

      .login-logo {
        position: absolute;
        top: 20px;
        left: 50px;
        font-weight: 700;
        font-size: 40px;

        @media (max-width: 700px) {
          text-align: center;
          top: 20px;
          left: 10px;
          font-size: 25px;
        }
      }

      .login-form-main {
        //background-color: white;
        width: 100%;
        height: fit-content;

        form {
          display: flex;
          flex-direction: column;

          .errmsg {
            border-radius: 20px;
          }

          .form-heading {
            h1 {
              margin-bottom: 20px;
              font-size: 1.5rem;
              font-weight: 700;
            }
            h5 {
              margin-bottom: 20px;
              font-size: 1rem;
              font-weight: 700;
            }

            @media (max-width: 700px) {
              h1 {
                font-size: 1rem;
                font-weight: 500;
              }

              h5 {
                font-size: 0.7rem;
                font-weight: 400;
              }
            }
          }

          input[type="text"],
          [type="email"],
          [type="password"] {
            margin-bottom: 15px;
            height: 60px;
            padding: 20px;
            background-color: white !important;
            border: 1px solid rgb(213, 213, 213);
            border-radius: 50px;
            outline: none;
          }
          .password-box {
            position: relative;
            display: flex;
            width: 100%;
            align-items: center;

            input[type="password"],
            [type="text"] {
              width: 100%;
            }

            button {
              position: absolute;
              right: 20px;
              cursor: pointer;
              border: none;
              background-color: transparent;
            }
          }

          .login-forgot-pass {
            display: flex;
            align-items: center;
            justify-content: right;
            margin-bottom: 20px;

            h4 {
              font-size: 17px;
              font-weight: 400;
              color: black;

              &:hover {
                cursor: pointer;
                color: gray;
              }
            }
          }

          .register-btn-main {
            button {
              border: none;
              background-color: black;
              color: white;
              font-size: 20px;
              width: 100%;
              padding: 20px;
              border-radius: 10px;
              cursor: pointer;
              transition: 0.5s;

              &:hover {
                background-color: rgb(42, 41, 41);
              }

              @media (max-width:500px) {
                font-size: 17px;
                padding: 10px;
              }
            }
          }

          .signup-continue {
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: center;

            .span-line {
              width: 100px;
              background-color: gray;
              height: 2px;
            }

            h6 {
              font-size: 15px;
              margin-left: 10px;
              margin-right: 10px;
              text-align: center;

              a {
                text-decoration: underline;
                cursor: pointer;
                color: black;
              }
            }
          }
        }
      }

      @media (max-width: 1300px) {
        padding-left: 50px;
        padding-right: 50px;
      }
      @media (max-width: 1200px) {
        width: 60%;
        padding-left: 100px;
        padding-right: 100px;
        margin: 0 auto;
      }
      @media (max-width: 900px) {
        width: 100%;
      }
      @media (max-width: 768px) {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
      }
    }

   

    @media (max-width: 700px) {
      padding: 10px;
    }
  }
}
