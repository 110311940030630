.pagination-container {
width: 100%;
display: flex;
justify-content: center;
  .pagination {

    display: flex;
    list-style: none;
    gap: 20px;

    li {
      padding: 7px 20px;
     // background-color: blue;
      border-radius: 6px;
      cursor: pointer;
      font-weight: 600;
      border: 1px solid black;

      &:nth-child(1) {
        background-color: rgba(24, 24, 224, 0.804);
        color: white;
        border: none;
      }
      &:nth-last-child(1) {
        background-color: rgba(24, 24, 224, 0.804);
        color: white;
        border: none;
      }

    }

  }
}