
.dashboard-main {
    
    height:100vh;
    width: 100%;
    padding-top:100px;
    padding-left: 283px;
   // background-color: black;
   
    
   //overflow-y:hidden;

   @media (max-width:970px) {
    padding-left: 0;
   }
   @media (max-width:500px) {
    padding-top:70px;
   }

   
   
}