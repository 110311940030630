.landing-header {
    padding: 20px 20px 20px 20px;
    background:  rgba(36, 3, 107, 0.287);
    //background-color: rgba(34, 4, 90, 0.871);
    position: relative;
    display: flex;
    justify-content: center;
    opacity: 5;
    z-index: 10;
   // border-bottom: .5px solid rgba(54, 86, 166, 0.745);
.header-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;

    .header-logo {

        h1 {
            color: white;
            font-size: 30px;
            font-weight: 700;

            @media (max-width:700px) {
               font-size: 20px;
            }
        }
    }

    .nav-main {

        ul {
            list-style: none;
            display: flex;
            li {
                margin-right: 20px;
                font-size: 17px;
                font-weight: 600;
                padding: 7px 15px;
                transition: .5s;
                border-radius: 10px;
                a{
                    text-decoration: none;
                    color: white;
                }

                &:hover {
                    background-color:rgb(36, 2, 108) ;
                }


            }

            @media (max-width:700px) {
                display: none;
            }
        }

        @media (max-width:850px) {
            display: none;
        }
    }

    .nav-responsive {

        .responsive {
            color: white!important;
            .res-btn {
                color: white!important;
                height: 30px!important;
                width: 30px!important;
                
            }
        }

        
        @media (min-width:850px) {
            display: none;
        }

    }

   
}
}