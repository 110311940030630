.transaction-main {
  width: 100%;

  .table-main {
    padding-top: 70px;
    margin: 0 auto;
    width: 80%;
    padding-bottom: 40px;
    padding-left: 10px;
    padding-right: 10px;

    background-color: white;

    .no-users {
      text-align: center;
      font-size: 30px;
      font-weight: 700;
      padding-top: 50px;
    }

    .transac-box-main {
      width: 100%;
      .transac-box-inner {
        width: 100%;
        padding: 20px;
        border-radius: 50px;
        box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.102);
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        font-weight: 600;
        color: rgba(155, 155, 155, 0.755);

        .action {
        }

        .message {
        }

        .amount {
        }

        .status {
          padding: 5px;
          padding-left: 10px;
          padding-right: 10px;
          border-radius: 10px;
          color: white;
        }

        .date {
        }

        @media (max-width: 500px) {
          border-radius: 5px;
          flex-direction: column;
         
        }
      }
    }

    /*
        table {
            width: 100%;
            box-shadow: 3px 5px 20px rgba(0, 0, 0, 0.04);
            border-radius: 5px;

            thead {

                tr {
                    th {
                        padding: 5px;
                        color: gray;
                        &:nth-child(1){
                            width: 30%;
                        }
                        &:nth-child(2){
                            width: 40%;
                        }
                        &:nth-child(3){
                            width: 30%;
                        }
                    }
                }
            }

            tbody {

                tr {

                    td {
                       
                        padding: 10px;
                    }

                    &:nth-child(odd){
                        background-color: rgba(216, 216, 216, 0.831);
                    }
                }

            }
        }
        */
        @media (max-width: 500px) {
         width: 100%;
        }
  }
}
