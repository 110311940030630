footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  //padding-bottom: 100px;
  background: #f0f4f9;

  .footer-inner {
    max-width: 1200px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 100px;

    .footer-sec-1 {
      background-color: white;
      padding-left: 20px;
      padding-right: 20px;
      height: 200px;
      text-align: center;
      position: relative;
      z-index: 0;
      margin-top: -200px;
      width: 250px;
      padding-top: 40px;

      h1{
        font-weight: 800;
        font-size: 24px;
      }

      &::after {
        content: "";
        z-index: 1;
        position: absolute;
        width: 100%;
        height: 10px;
        background-color:#fdc800;
        top: 0;
        left: 0;
      }

      .footer-sec1-socials{
        display: flex;
        justify-content: space-around;
        margin-top: 30px;

        span {
          width: 38px;
          height: 38px;
            border-radius: 50%;
            border: 1px solid gray;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
        }
      }
    }

    .footer-sec-2 {
      padding-left: 20px;
      padding-right: 20px;
      height: 300px;
      width: 250px;

      h2{
        color: black;
        font-weight: 700;
        font-size: 20px;
      }

      ul {
        margin: 0 !important;
        list-style: none;

        padding-inline-start: 0px;

        li {
          margin-bottom: 10px;

          a{
            text-decoration: none;
            color: rgb(150, 150, 150);
          }
        }
      }
    }

    .footer-sec-3,
    .footer-sec-4 {
      padding-left: 20px;
      padding-right: 20px;
      height: 300px;
      width: 250px;

      h2 {
        color: black;
        font-weight: 700;
        font-size: 20px;
      }

      ul {
        margin: 0 !important;
        list-style: none;

        padding-inline-start: 0px;

        li {
          margin-bottom: 10px;
          font-size: 17px;
          font-weight: 500;

          a{
            text-decoration: none;
            color: rgb(150, 150, 150);
          }
        }
      }
    }

    @media (max-width:850px) {
     flex-wrap: wrap;
      align-items: center;

       
      .footer-sec-2, 
      .footer-sec-3 {
        height: 200px;
      }
      
      .footer-sec-1 {
       margin-bottom: 30px;
      }
    }

   
  }
  .copyright {
    display: block;
    width: 100%;
    

    .copyright-inner {
      max-width: 1200px;
      padding-top: 10px;
      padding-bottom: 10px;
      width: 100%;
      border-top: 1px solid rgb(196, 196, 196);
      margin: 0 auto;
      text-align: center;
    }
  }
}
