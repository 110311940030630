
.error-main {
    width: 100%;
    height: 100svh;

    .error-inner {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        h1 {
            font-size: 10rem;
            font-weight: 700;
            margin-bottom: 30px;


            @media (max-width:860px) {
                font-size: 7rem;
            }

            @media (max-width:500px) {
                font-size: 3rem;
            }

        }

        h3{
            font-size: 25;
            font-weight: 700;
            margin-bottom: 30px;
        }
    }
}