.profile-main {
    display: flex;
    justify-content: center;
    align-items: center;

    .profile-section{
        width: 100%;
        max-width: 1000px;
        padding: 20px;

        form {
            div {
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;
                justify-content: flex-start;

                label {
                    margin-bottom: 5px;
                    color: rgba(155, 155, 155, 0.755);
                    font-size: 14px;
                    font-weight: 600;
                }

                input {
                    width: 100%;
                    border: 1px solid  rgba(194, 193, 193, 0.563);
                    border-radius: 6px;
                    padding: 6px;
                    background-color:rgba(194, 193, 193, 0.48) ;
                    color: slategray;
                    font-size: 14px;
                    font-weight: 600;
                }

                button {
                    padding: 7px 15px;
                    border-radius: 5px;
                    background-color: navy;
                    width: fit-content;
                    color: white;
                    font-size: 14px;
                    font-weight: 700;
                    margin-top: 10px;
                    transition: .5s;

                    &:hover {
                        opacity: .8;
                    }
                }

                &:nth-child(4){
                    input {
                        margin-bottom: 10px;
                        background-color:rgba(241, 237, 237, 0.839) ;
                    }
                }
            }
        }
    }
}