.home-main {
  //////////////////////////////////////////////////////
  .hero-main {
    background-image: url("../../Assets/Image/Zlanding.webp");
    background-size: cover;
    background-repeat: no-repeat;
    height: 105svh;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 0;
   margin-top: -100px;
    .hero-section {
      z-index: 5;
      display: flex;
      width: 100%;

      max-width: 1200px;

      .hero-image {
        width: 40%;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        //background-color: aquamarine;

        span {
          height: 400px;
          width: 400px;
          rotate: 30deg;
          // background-color: red;

          img {
            width: 100%;
          }
        }

        @media (max-width: 850px) {
          span {
            // background-color: green;
            height: 250px;
            width: 300px;
          }
        }

        @media (max-width: 700px) {
          height: fit-content;
          width: 100%;
          //background-color: red;

          span {
            margin-top: 50px;
            height: 250px;
            width: 250px;
            rotate: 30deg;
            // background-color: red;

            img {
              width: 100%;
            }
          }
        }

        @media (max-width:500px) {
          margin-top: 70px;
        }
      }

      .landing-desc {
        width: 60%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        //background-color: rgb(6, 139, 95);

        .sub1 {
          height: 35px;
          background: rgba(255, 255, 255, 0.2);
          display: inline-block;
          border-radius: 35px;
          line-height: 35px;
          padding: 0 30px;
          font-size: 14px;
          font-weight: 400;
          color: #fff;
          margin-bottom: 20px;
        }

        .sub2 {
          font-size: 35px;
          font-weight: 700;
          color: white;
          line-height: 1.23;
          margin-bottom: 30px;
          //max-width: 600px;
          //background-color: red;

          span {
            font-size: 20px;
            color: rgba(231, 231, 231, 0.771);
            font-weight: 400;
          }

          @media (max-width: 700px) {
            font-size: 25px;
            text-align: center;
          }
        }

        .sub3 {
          a {
            button {
              height: 55px;
              line-height: 55px;
              padding: 0 36px;
              border-radius: 0;
              overflow: hidden;
              position: relative;
              border: 0;
              font-size: 15px;
              transition: all 0.5s ease;
              font-weight: 600;
              border-radius: 4px;
              margin-right: 10px;
              background-color: rgb(61, 5, 183);
              color: white;
              transition: 0.5s;
              cursor: pointer;

              &:hover {
                color: white;
                background: transparent;
                border: 1px solid gray;
              }
            }

            &:nth-last-child(1) {
              button {
                color: white;
                background: transparent;
                border: 1px solid gray;

                &:hover {
                  background-color: rgb(61, 5, 183);
                }
              }
            }
          }
        }

        @media (max-width: 850px) {
          padding-left: 10px;
          padding-right: 10px;
        }
        @media (max-width: 700px) {
          width: 100%;
          height: fit-content;
          align-items: center;
        }
      }

      @media (max-width: 700px) {
        flex-direction: column;
        align-items: center;
        //justify-content: center;
      }
    }

    &::before {
      content: "";
      z-index: 2;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background-color: rgba(44, 4, 90, 0.711);
    }
  }
  ///////////////////////////////////////////
  ///
  ///
  ///
  .features-sec {
    display: flex;
    justify-content: center;

    .features-sec-inner {
      box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.252);
      background: #fff;
      padding: 50px 0 50px;
      border-radius: 8px;

      margin-top: -90px;

      .feature-sec-1 {
        display: flex;
        flex-direction: column;
        align-items: center;

        .heading-title {
          display: flex;
          align-items: center;
          div {
            height: 1px;
            width: 100px;
            background-color: navy;
          }

          h4 {
            margin-left: 10px;
            margin-right: 10px;
          }
        }

        .heading-sub {
          font-size: 46px;
          line-height: 1.3380952381;
          font-weight: 700;
          text-align: center;
        }
      }

      .feature-sec-2 {
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 43px;

        div {
          text-align: center;
          padding-left: 15px;
          padding-right: 15px;

          img {
            width: 100%;
          }
        }
      }
    }
  }
  /////////////////////////////////////////////////////
  ///
  ///
  ///
  .about-sec-main {
    
    display: flex;
    justify-content: center;
    margin-top: 100px;

    .about-sec-inner {
      display: flex;
      max-width: 1200px;
      width: 100%;

      .about-sec1 {
        width: 50%;
        // background-color: red;
        padding-right: 20px;
        position: relative;
        .about-sec1-bck {
          height: 400px;
          width: 150px;
          background-color: orange;
          position: absolute;
          border-radius: 5px;
          top: 0;
          right: 30px;
          z-index: -1;
          @media (max-width: 700px) {
            display: none;
          }
        }

        img {
          z-index: 2;
        }

        @media (max-width: 700px) {
          width: 100%;
          padding-right: 0px;
        }

        img {
          z-index: 2;
          width: 100%;
          padding-right: 20px;
        }
      }

      .about-sec2 {
        padding-top: 30px;
        width: 50%;

        .about-sec2-heading {
          display: flex;
          align-items: center;
          h4 {
            font-size: 16px;
            font-weight: 500;
            margin-right: 10px;
          }
          span {
            height: 1px;
            background-color: navy;
            width: 100px;
          }
        }

        .about-sec2-sub {
          font-size: 46px;
          font-weight: 700;
        }

        p {
          margin-top: 20px;
        }

        .about-sec2-btn {
          margin-top: 40px;
          button {
            border: 2px solid orange;
            height: 55px;
            padding: 0 36px;
            font-size: 15px;
            font-weight: 500;
            border-radius: 4px;
            background: transparent;
            cursor: pointer;
          }
        }

        @media (max-width: 700px) {
          width: 100%;

          padding-left: 10px;
          padding-right: 10px;
        }

        .about-sec2-sub {
          font-size: 30px;
          font-weight: 700;
        }
      }

      @media (max-width: 700px) {
        flex-direction: column;
      }
    }
  }
  ///////////////////////////////////////////
  ///
  ///
  ///
  .featured-courses {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    .featured-courses-inner {
      max-width: 1200px;
      width: 100%;
      //background-color: red;

      .featured-courses-heading {
        display: flex;
        // justify-content: center;
        flex-direction: column;
        align-items: center;

        .featured-courses-heading1 {
          display: flex;
          justify-content: center;
          align-items: center;

          span {
            height: 1px;
            background-color: navy;
            width: 100px;
          }

          h3 {
            margin-left: 10px;
            margin-right: 10px;
            font-size: 16px;
            font-weight: 500;
          }
        }

        .featured-courses-heading2 {
          font-size: 46px;
          font-weight: 700;

          @media (max-width: 700px) {
            font-size: 30px;
          }
        }
      }

      .featured-courses-card {
        //-color: black;
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(3, 1fr);
        margin-top: 50px;

        .courses-card-main {
          border: 1px solid rgba(188, 187, 187, 0.548);
          overflow: hidden;
          width: 100%;
          border-radius: 10px;
          position: relative;

          .courses-card-icon {
            padding-top: 10px;
            color: #002147;
            display: flex;
            justify-content: center;
            font-size: 2.5rem;
          }
          .courses-card-title {
            padding-top: 10px;
            color: #002147;
            display: flex;
            justify-content: center;
            font-size: 1rem;
          }

          .courses-card-text {
            padding: 20px;
            margin-bottom: 50px;
          }

          .courses-card {
            position: absolute;
            bottom: 0;
            width: 100%;
            //background-color: brown;
            border-top: 1px solid rgba(120, 120, 120, 0.359);
            transition: 0.5s;
            cursor: pointer;
            .courses-card-desc {
              padding: 14px 20px;
              margin-top: 0;

              .courses-card-desc-inner {
                display: flex;
                justify-content: space-between;
                align-items: center;

                //background-color: green;
                .courses-card-desc-title {
                  font-size: 20px;
                }
                .courses-card-desc-icon {
                  font-size: 25px;
                }
              }
            }
          }

          &:hover img {
            transform: scale(1.1);
          }
          &:hover .courses-card {
            background-color: #002147;
            color: white;
          }
        }

        @media (max-width: 850px) {
          grid-template-columns: repeat(2, 1fr);
          margin-top: 50px;
          padding-left: 20px;
          padding-right: 20px;
        }
        @media (max-width: 700px) {
          grid-template-columns: repeat(1, 1fr);
          margin-top: 50px;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }
  /////////////
  ///
  ///
  ///
  .news-letter-main {
    margin-top: 100px;
    margin-bottom: -130px;
    display: flex;
    justify-content: center;
    //background-color: red;
    position: relative;
    z-index: 2;

    .news-letter-inner {
      max-width: 1200px;
      width: 100%;
      display: grid;
      gap: 2rem;
      grid-template-columns: repeat(2, 1fr);

      .news-letter-sec1 {
        background-color: black;
        border-radius: 7px;
        padding: 43px 0 36px 35px;
        background-image: url("../../Assets/Image/newsletter.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        color: white;

        h3 {
          font-size: 25px;
          font-weight: 500;
          line-height: 1.3380952381;
          margin: 0;
        }

        .news-letter-sec1-text {
          max-width: 400px;
        }
      }

      .news-letter-sec2 {
        background-color: #fdc800;
        border-radius: 7px;
        padding: 41px 40px 30px;

        h3 {
          font-size: 28px;
          font-weight: 500;
          line-height: 1.3380952381;
          margin: 0;

          @media (max-width: 700px) {
            font-size: 20px;
          }
        }

        p {
          margin-bottom: 35px;
        }
        .news-letter-sec2-input {
          display: flex;

          position: relative;
          align-items: center;

          input {
            height: 73px;
            border-radius: 10px !important;
            border: 0 !important;
            font-size: 15px;
            color: #636363;
            padding: 0 190px 0 35px;
            width: 100%;
            outline: none;

            @media (max-width: 700px) {
              padding: 0 160px 0 20px;
            }
          }

          button {
            position: absolute;
            background-color: #002147;
            right: 6.5px;
            border-radius: 10px;
            height: 60px;
            line-height: 60px;
            padding: 0 30px;
            color: white;
            font-size: 15px;
            font-weight: 500;
            //top: 6.5px;
          }
        }
        @media (max-width: 700px) {
          padding: 20px 20px 20px;
        }
      }

      @media (max-width: 850px) {
        grid-template-columns: repeat(1, 1fr);
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  ////////////
  ///
  ///
  ///
  .other-service-main {
    display: flex;
    justify-content: center;
    height: auto;
    background-color: antiquewhite;
    z-index: 0;
    .other-service-inner {
      max-width: 1200px;
      width: 100%;

      .other-service-heading-main {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 250px;
        //justify-content: center;

        .other-service-heading-inner {
          display: flex;

          align-items: center;

          span {
            height: 1px;
            background-color: navy;
            width: 100px;
          }

          h3 {
            margin-left: 10px;
            margin-right: 10px;
            font-size: 16px;
            font-weight: 500;
          }
        }

        .other-service-heading1 {
          font-size: 46px;
          font-weight: 700;
          margin: 0;

          @media (max-width: 700px) {
            font-size: 30px;
          }
        }
      }

      .other-service-cards {
        margin-top: 100px;
        margin-bottom: 100px;
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(3, 1fr);

        div {
          background-color: white;
          height:auto;
          border-radius: 10px;
          padding: 20px;
          font-size: 20px;
          font-weight: 700;
          
          p{
            margin-top: 5px;
            font-size: 14px;
            color: rgb(148, 148, 148);
          }
        }

        @media (max-width: 850px) {
          padding-left: 10px;
          padding-right: 10px;
        }
        @media (max-width: 700px) {
          grid-template-columns: repeat(1, 1fr);
          padding-left: 20px;
          padding-right: 20px;
        }
      }
    }
  }
  ////////////
  ///
  ///
  ///
  .services-carousel {
    padding-top: 100px;
    padding-bottom: 100px;
    background-color: #fdc800;
    background-image: url("../../Assets/Image/carouselbg.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;

    .services-carousel-inner {
      max-width: 1200px;
      width: 100%;
      //display: flex;

      justify-content: center;
      .services-img-main {
        // background-color: red;
        //margin-left: 10px;
        //margin-right: 10px;
        width: 30% !important;
        display: flex;
        justify-content: center;
        img {
          height: 70px;
          width: 70px;
        }
      }
    }
  }
  ////////////
  ///
  ///
  ///

  ////////////
  ///
  ///
  ///

  ////////////
}

.swiper-pagination {
  margin-top: 40px !important;
  position: absolute !important;
  bottom: 0 !important;
}
