.user-home {
  //display: flex;
  width: 100%;
  height: fit-content;
  // overflow-y: scroll;
  // align-items: center;
  //justify-content: center;
  background-color: whitesmoke;
  padding: 60px 60px 30px 60px;

  .home-wallet-card {
    h4 {
      //font-family: 'Work Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #213f7d;
      font-weight: 600;
    }
  }

  .service-card {
    h4 {
      //font-family: 'Work Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #213f7d;
      font-weight: 600;
    }

    .cards-main {
      width: 100%;
      height: fit-content;
      margin-top: 20px;
      margin-bottom: 40px;
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      //justify-content: center;

      .card-detail {
        a {
          width: 240px;
          height: 160px;
          padding: 20px 30px 30px 30px;
          margin-left: 20px;
          background: #ffffff;
          border: 1px solid rgba(33, 63, 125, 0.06);
          box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 4px 9px 1px rgba(50, 71, 92, 0.04),
                 0px 2px 9px 0px rgba(50, 71, 92, 0.06);
          border-radius: 8px;

          transition: 0.5s;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          position: relative;

          sup {
            position: absolute;
            top: 10px;
            right: 5px;
            font-weight: 500;
            margin-bottom: 10px;
          }

          h4 {
            margin-top: 12px;
            font-family: sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 17px;
            line-height: 28px;
            text-transform: uppercase;
            color: #545f7d;
            text-align: center;

            @media (max-width: 500px) {
              font-size: 13px;
            }
          }

          .card-icon {
            height: 40px;

            @media (max-width: 500px) {
              height: 30px;
            }
          }

          &:hover {
            background-color: whitesmoke;
          }

          @media (max-width: 500px) {
            width: 150px;
            height: 150px;
            margin-left: 6px;
            padding: 10px 10px 10px 10px;
          }
        }
      }

      @media (max-width: 500px) {
        justify-content: center;
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @media (max-width: 970px) {
    padding: 30px 60px 30px 30px;
  }
  @media (max-width: 940px) {
    padding: 30px 50px 30px 50px;
  }
  @media (max-width: 500px) {
    padding: 30px 20px 30px 20px;
  }
}
