.form-section {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    width: 70%;
    padding-left: 10px;
    padding-right: 10px;
    .form-section-text {
      
      //background-color: red;
      margin-bottom: 30px;

      h4 {
        font-weight: 500;
        color: #002147;
        font-size: 16px;
      }
      h2 {
        font-weight: 700;
        color: #002147;
        font-size: 46px;
        margin-top: 5px;
      }

      p {
        color: gray;
        margin-top: 1rem;
      }

      @media (max-width:850px) {
        width: 100%;
        margin-bottom: 30px;
        padding-left: 10px;
        padding-right: 10px;
      }
      @media (max-width:500px) {
        h2 {
            font-size: 30px;
          }
      }
    }

    .form-main {
      
      //background-color: gray;

      form {
       
        display: flex;
        flex-direction: column;
        .form-input-1,
        .form-input-2 {
          display: flex;

          div {
            width: 50%;
            margin-bottom: 20px;
           
            input {
              width: 100%;
              border: 2px solid rgba(8, 76, 148, 0.2) !important;
              height: 58px;
              border-radius: 4px;
              padding: 0 18px;
              outline: none;
            }

            &:nth-child(odd){
                margin-right: 15px;
              }
              &:nth-child(even){
                margin-left: 15px;
              }

              @media (max-width:500px) {
                &:nth-child(odd){
                    margin-right: 5px;
                  }
                  &:nth-child(even){
                    margin-left: 5px;
                  }
              }
          }
        }

        textarea {
          //width: 100%;
          border: 2px solid rgba(8, 76, 148, 0.2) !important;
          height: 140px;
          border-radius: 5px;
          padding: 14px 18px;
         
        }

        .contact-sub-btn {
          padding-left: 15px;
          padding-top: 20px;
          button {
            height: 55px;
            line-height: 55px;
            padding: 0 36px;
            border-radius: 0;
            border: 0;
            font-size: 15px;
            transition: all 0.5s ease;
            font-weight: 500;
            border-radius: 4px;
            background-color: orange;
            cursor: pointer;
          }
        }
      }

      @media (max-width:850px) {
        width: 100%;
        margin-bottom: 100px;
      }
    }

    @media (max-width:850px) {
      flex-direction: column;
    }

    @media (max-width:500px) {
        width: 100%;
    }
  }