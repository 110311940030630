.aboutUs-main {
  .who-we-are {
    .who-we-are-inner {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      gap: 100px;
      padding-top: 100px;
      padding-bottom: 100px;
      .who-we-are-img {
        max-width: 500px;
        width: 100%;
        height: 400px;
        background-image: url("../../Assets/Image/educational.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        //background-color: red;
      }

      .who-we-are-text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h1 {
          color: #002147;
          font-size: 45px;
          font-weight: 700;
          margin-bottom: 15px;
        }

        p {
          margin-bottom: 8px;
          font-size: 16px;
          font-weight: 500;
        }

        .who-we-are-btn {
          button {
            height: 55px;
            line-height: 55px;
            padding: 0 36px;
            border-radius: 0;
            border: 0;
            font-size: 15px;
            transition: all 0.5s ease;
            font-weight: 500;
            border-radius: 4px;
            background-color: orange;
            color: white;
            cursor: pointer;
          }
        }
      }

      @media (max-width: 850px) {
        gap: 30px;

        .who-we-are-img {
          max-width: 300px;
          width: 100%;
        }
      }

      @media (max-width: 700px) {
        flex-direction: column;

        .who-we-are-img {
          //background-color: red;
          max-width: 100%;
          width: 100%;
          height: 200px;
          background-position: center;
        }

        .who-we-are-text {
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      @media (max-width: 700px) {
        padding-top: 50px;
        padding-bottom: 100px;
        gap: 30px;
      }
    }
  }

  /////////////////////////////////////////
  ///
  ///
  ///

  .our-story {
    padding-top: 100px;
    padding-bottom: 100px;
    background: whitesmoke;

    .our-story-inner {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      display: flex;
      gap: 20px;

      .our-story-text {
        width: 60%;
        h1 {
          color: #002147;
          font-size: 45px;
          font-weight: 700;
          margin-bottom: 15px;
        }

        p {
          margin-bottom: 20px;

          span{
            font-size: 20px;
            color: #002147;
            font-weight: 500;
          }
        }

        @media (max-width:500px) {
          width: 100%;
        }
      }

      .our-story-img {
        max-width: 500px;
        width: 100%;
        height: 400px;
        background-image: url("../../Assets/Image/ourstory.webp");
        background-size: contain;
        background-repeat: no-repeat;
      }

      @media (max-width: 850px) {
        flex-direction: column;

        .our-story-text {
          padding-left: 10px;
          padding-right: 10px;
        }

        .our-story-img {
          max-width: 100%;
          width: 100%;
          height: 300px;
        }
      }
    }

    .our-story-inner2 {
      max-width: 1200px;
      width: 100%;
      margin: 0 auto;
      display: flex;
     justify-content: center;
      .our-story-text {
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
        text-justify: distribute;
        h1 {
          text-align: center;
          color: #002147;
          font-size: 45px;
          font-weight: 700;
          margin-bottom: 15px;
        }

        p {
          margin-bottom: 20px;

          span{
            font-size: 20px;
            color: #002147;
            font-weight: 500;
          }
        }
      }

     

    }

    @media (max-width: 700px) {
      padding-top: 50px;
    }
  }
  /////////////////////////////
  ///
  ///
  .news-letter-main {
    margin-top: 100px;
    margin-bottom: 100px;
    display: flex;
    justify-content: center;
    //background-color: red;
    position: relative;
    z-index: 2;

    .news-letter-inner {
      max-width: 1200px;
      width: 100%;
      display: grid;
      gap: 2rem;
      grid-template-columns: repeat(2, 1fr);

      .news-letter-sec1 {
        background-color: black;
        border-radius: 7px;
        padding: 43px 0 36px 35px;
        background-image: url("../../Assets/Image/newsletter.png");
        background-size: contain;
        background-repeat: no-repeat;
        background-position: right;
        color: white;

        h3 {
          font-size: 25px;
          font-weight: 500;
          line-height: 1.3380952381;
          margin: 0;
        }

        .news-letter-sec1-text {
          max-width: 400px;
        }
      }

      .news-letter-sec2 {
        background-color: #fdc800;
        border-radius: 7px;
        padding: 41px 40px 30px;

        h3 {
          font-size: 28px;
          font-weight: 500;
          line-height: 1.3380952381;
          margin: 0;

          @media (max-width: 700px) {
            font-size: 20px;
          }
        }

        p {
          margin-bottom: 35px;
        }
        .news-letter-sec2-input {
          display: flex;

          position: relative;
          align-items: center;

          input {
            height: 73px;
            border-radius: 10px !important;
            border: 0 !important;
            font-size: 15px;
            color: #636363;
            padding: 0 190px 0 35px;
            width: 100%;
            outline: none;

            @media (max-width: 700px) {
              padding: 0 160px 0 20px;
            }
          }

          button {
            position: absolute;
            background-color: #002147;
            right: 6.5px;
            border-radius: 10px;
            height: 60px;
            line-height: 60px;
            padding: 0 30px;
            color: white;
            font-size: 15px;
            font-weight: 500;
            //top: 6.5px;
          }
        }
      }

      @media (max-width: 850px) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
