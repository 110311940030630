.wallet-card-main{
    width: 100%;
    //height: 160px;
    margin-top: 20px;
    margin-bottom: 40px;


.card-detail {
    width: 100%;
    height: 100%;
    padding: 20px 30px 30px 30px;
    background: #FFFFFF;
    border: 1px solid rgba(33, 63, 125, 0.06);
    box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 4px 9px 1px rgba(50, 71, 92, 0.04),
    0px 2px 9px 0px rgba(50, 71, 92, 0.06);
    border-radius: 8px;
    .card-title {
        margin-top: 14px;
        font-family:sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        color: #545F7D;
    }

    .wallet-pocket{

        display: flex;
        align-items: center;
       
        h4 {
            margin-top: 12px;
            font-family:sans-serif;
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 28px;
            text-transform: uppercase;
            color: #213F7D;
    
        }

        .pocket-btn {
            margin-left: 10px;
        }

    }

    

    .wallet-fund-btn {
        padding-top: 20px;
        //padding-bottom: 20px;
        button {
            background-color: #213F7D;
            color: white;
            font-size: 16px;
            font-weight: 500;
            border: none;
            padding: 10px 20px;
            border-radius: 5px;
            cursor: pointer;
            transition: .5s;

            &:hover {
                background-color: #2c55a7;
            }
        }
    }
}

}